<template>
  <div class="rule-list">
    <b-card>
      <div class="row">
        <div class="col-sm-12 mb-2">
          <b-button class="float-right" variant="warning" @click="openModalNew" :disabled="loading"><feather-icon icon="PlusIcon"/> Crear notificación</b-button>
        </div>
        <div class="col-sm-12">
          <table-render v-show="!loading" id="notifs" :show-checkboxes="false" :actions="actions" :schema="schema" :rows="rows" :loading="pagination.loading"/>
          <div class="table-render-skeleton" v-show="loading">
            <b-skeleton-table
              :rows="10"
              :columns="schema.length || 10"
              :table-props="{ }"/>
          </div>
          <pagination :pagination="pagination" :showSize="true"/>
        </div>
      </div>
    </b-card>

    <modal-notification ref="modalNewNotification" :data.sync="dialogData" @savedNotification="savedNotification" :syncNotifications="syncNotifications"></modal-notification>

  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ModalNotification from './ModalNotification'
import Swal from 'sweetalert2'

export default {
  components: {ModalNotification},
  props: ['user', 'data'],
  data () {
    return {
      actions: [],
      dialogOpen: false,
      dialogData: {},
      notifications: [],
      schema: [],
      rows: [],
      keyTableRender: 0,
      loading: true,
      pagination: { page: 1, limit: 10, total: 0, loading: false },
      currentNotifications: []
    }
  },
  watch: {
    'pagination.limit' () {
      this.getData()
    },
    'pagination.page' () {
      this.getData()
    },
    mySession () {
      if (this.mySession.id) this.setInitialData()
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession'
    })
  },
  methods: {
    async savedNotification (id) {
      if (id) {
        // Crear el _id en cada notificación esto para tener la misma estructura que las notificaciones antiguas
        try {
          await this.$root.$data.notificationsCollection.doc(id).update({_id: id})
          this.$success(this.$t('msg-exito-agregar-notificacion'))
        }
        catch {
          this.$alert(this.$t('Error al crear la notificación no fue posible agregar el valor _id'))
        }  
      }
      this.getCurrentNotifications()
    },
    async syncNotifications () {
      Swal.fire({
        icon: 'warning',
        title: 'Problemas de sincronización',
        html: 'Cargando...',
        showConfirmButton: false
      })
      this.pagination.loading = true
      const fb = await this.$root.$data
      const allNotifs = (await fb.notificationsCollection.orderBy('created', 'asc').get()).docs
      const myArray = []
      for (const rawRow of allNotifs) {
        myArray.push(rawRow.id)
      }
      this.currentNotifications = [...myArray]
      await fb.currentNotificationsCollection.doc('all').set({data: this.currentNotifications})
      this.getCurrentNotifications()
      this.$success(this.$t('msg-exito-sync-notificacion'))
      return this.currentNotifications
    },
    stripHTML (html) {
      const tmp = document.createElement('DIV')
      tmp.innerHTML = html
      return tmp.textContent || tmp.innerText || ''
    },
    confirmDelete (id) {
      this.$yesno(this.$t('msg-pregunta-eliminar-elemento'), _ => this.deleteNotification(id))
    },
    deleteNotification (id) {
      this.pagination.loading = true
      const firebase = this.$root.$data
      firebase.notificationsCollection.doc(id).delete()
        .then(resp => {
          firebase.currentNotificationsCollection.doc('all').update({
            data: firebase.removeA(id)
          })
            .then(resp => {
              this.$success(this.$t('msg-exito-eliminar-notificacion'))
              this.getCurrentNotifications()
            })
            .catch(err => { this.$alert(this.$t('msg-problema-eliminar-elemento', {code: err})); this.pagination.loading = true })         
        })
        .catch(err => { this.$alert(this.$t('msg-problema-eliminar-elemento', {code: err})); this.pagination.loading = true })
    },
    openModalNew () {
      this.dialogData = {}
      this.$bvModal.show('modalNewNotification')
    },
    openModal (_id) {
      this.dialogData = this.rows.filter(({id}) => id === _id)[0]
      if (this.dialogData.created < 1628718179810) {
        this.$alert(this.$t('msg-error-notif'))
      } else {
        this.$bvModal.show('modalNewNotification')
      }
    },
    parseRows (rows) {
      // procesar filas para procesar campos que requieren lógica
      this.rows = []
      for (const rawRow of rows) {
        const row = rawRow.data()
        const id = rawRow.id
        const enabling_date = row.enabling && row.enabling !== 0 ? `Desde: <br> ${this.$options.filters.moment(new Date(row.enabling), 'YYYY-MM-DD')} ` : null
        const expiration_date = row.expiration && row.expiration !== 9999999999999 ? `Hasta: <br> ${this.$options.filters.moment(new Date(row.expiration), 'YYYY-MM-DD')}` : null
        this.rows.push({
          id,
          title: row.title,
          created: row.created,
          date: this.$options.filters.moment(new Date(row.created), 'YYYY-MM-DD HH:mm:ss'),
          cleanContent: `${this.stripHTML(row.content.replace(/></g, '> <')).substring(0, 200)}...`,
          content: row.content,
          country_name: row.country && row.country.text ? row.country.text : null,
          country: row.country,
          companies: row.companies,
          type: row.type,
          exclude_type: row.exclude_type,
          exclude_onlyCompanyId: row.exclude_onlyCompanyId,
          shipper_id: row.shipper_id,
          category: row.category,
          carriers: row.carriers,
          onlyCompanyId: row.onlyCompanyId,
          inactive: row.inactive,
          expiration: row.expiration,
          enabling: row.enabling,
          views: row.views,
          publication: enabling_date || expiration_date ? `${enabling_date ? enabling_date : ''}${expiration_date ? expiration_date : ''}` : null,
          icon_active: `<i class='fa fa-${!row.inactive ? 'check-circle text-success' : 'times-circle text-danger'}'></i>`,
          account_type: row.account_type,
          direct_billing: row.direct_billing
        })
      }
      this.rows = this.rows.sort((a, b) => a.created < b.created ? 1 : -1)
      this.loading = false
      this.pagination.loading = false
      this.keyTableRender++
    },
    async getCurrentNotifications () {
      const firebase = await this.$root.$data
      this.currentNotifications = (await firebase.currentNotificationsCollection.doc('all').get()).data().data
      // Voltear el array ya que los nuevos estan al final
      this.currentNotifications.reverse()
      this.pagination.total = this.currentNotifications.length
      this.getData()
    },
    async getData () {
      this.pagination.loading = true
      const firebase = await this.$root.$data
      const from = (this.pagination.page - 1) * this.pagination.limit
      const notifsToGet = this.currentNotifications.slice(from, this.pagination.limit + from)
      let idsToGet = []
      let data = []
      let index = 0
      do {
        idsToGet = notifsToGet.slice((index * 10), 10 + (index * 10))
        const current = (await firebase.notificationsCollection
          .where(firebase.fb.firestore.FieldPath.documentId(), 'in', idsToGet).get()).docs
        data = [...data, ...current]
        index++
      } 
      while ((index * 10) < notifsToGet.length)

      this.parseRows(data)
    },
    getAllInitialData () {
      if (this.mySession.id) this.setInitialData()
    },
    setInitialData () {
      this.getCurrentNotifications()
      this.actions = [
        {action: this.openModal, icon: 'Edit2Icon', color: 'primary', text: 'Editar'},
        {action: this.confirmDelete, icon: 'TrashIcon', color:'danger', text: 'Eliminar'}
      ]

      this.schema = [
        {label: 'Título', sortable: true, key: 'title', style: {width: '20%'}},
        {label: 'Contenido', sortable: true, key: 'cleanContent', style: {width: '60%'}},
        {label: 'Creado', sortable: true, key: 'date'},
        {label: 'Publicación', sortable: true, key: 'publication', useDangerHTML: true },
        {label: 'País', sortable: true, key: 'country_name'},
        {label: 'Vistas', sortable: true, key: 'views', style: {width: '10%'}},
        {label: 'Activo', sortable: true, key: 'icon_active', style: {width: '10%'}, class: ['text-center'], useDangerHTML: true },
        {label: 'Acciones', key: 'actions', style: {width: '10%'}, class: ['text-center']}
      ]
    }
  },
  mounted () {
    this.getAllInitialData()
  }
}
</script>


<style lang="scss">
//.con-vs-dialog .vs-dialog.full {
//  max-width: 1000px;
//}
</style>
